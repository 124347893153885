<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новая запись поурочного планирования"
        create
        url-update="/lesson-plan/update/:id"
        url-back="/lesson-plan"
    >
        <lesson-plan-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import LessonPlanForm from '@/components/forms/LessonPlanForm.vue'
export default {
    name: 'LessonPlanCreate',
    components: { FormView, LessonPlanForm }
}
</script>